import React, { useEffect, useRef, useState } from "react";

// components
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// custom components
import Breadcrumb from "../../common/breadcrumb";

// tab panes
import Personal from "./save/Personal";
import Job from "./save/Job";
import Passport from "./save/Passport";
import Experience from "./save/Experience";
import Education from "./save/Education";
import Family from "./save/Family";
import DetailInSinhala from "./save/DetailInSinhala";
import CustomerStatus from "./save/CustomerStatus";
import Contact from "./save/Contact/Contact";
import Photo from "./save/Photo";
import { useParams } from "react-router-dom";
import {
  ABOUT_TO_CHECK,
  CHECKED,
  CHECKING,
  CUSTOMER_EXIST,
  CUSTOMER_NOT_EXIST,
} from "../../../constant";
import formValidation from "../../../customHooks/useValidation";
import Swal from "sweetalert2";
import useAxiosTemplates from "../../../customHooks/useAxiosTemplates";
import { useSelector } from "react-redux";

import searchImage from "../../../assets/images/search_customer.png";
import { useDispatch } from "react-redux";
import { LoadStaticURL } from "../../../data/util/LoadStaticURL";
import ConnectedSource from "./save/ConnectedSource";
import VisitDetail from "./save/VisitDetail";
import ConfigDB from "../../../data/customizer/config";
import Language from "./save/Language";
import Skill from "./save/Skill";
import Remark from "./save/Remark";
import { Check, UserCheck } from "react-feather";

const Index = ({ b_partner_id = 0, client_id = 0, org_id = 0 }) => {
  const { customer_id, tab_id } = useParams();
  // custom hooks
  const sendRequest = useAxiosTemplates();
  const dispatch = useDispatch();

  const loggedUser = useSelector((content) => content.UserReducer);
  let childLinks = [
    {
      value: `${customer_id !== undefined ? customer_id : "Customer"}`,
      active: false,
    },
    { value: b_partner_id ? "Update" : "New", active: true },
  ];
  const [basicTab, setBasicTab] = useState("save_customer_tab_1");
  const [triggerShourtcut, setTriggerShourtcut] = useState(false);

  const [bPartnerId, setbPartnerId] = useState(b_partner_id);
  const [clientId, setClientId] = useState(client_id);
  const [orgId, setOrgId] = useState(org_id);
  const [searchKey, setSearchKey] = useState("");
  const [firstName, setFirstName] = useState("");
  const [checkExistence, setCheckExistence] = useState(
    customer_id !== undefined ? CHECKED : ABOUT_TO_CHECK
  );
  const [customerExist, setCustomerExist] = useState(CUSTOMER_NOT_EXIST);
  const [customerData, setCustomerData] = useState(undefined);
  const nicIF = useRef("");
  const nicNumber = useRef("");

  const setbPartnerFromChild = (id) => {
    setbPartnerId(id);
  };
  const setClientFromChild = (id) => {
    setClientId(id);
  };
  const setOrgFromChild = (id) => {
    setOrgId(id);
  };

  const checkCustomer = (evt) => {
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      setCheckExistence(CHECKING);
      let reqData = {
        clientId: loggedUser.clientId,
        nicNo: nicIF.current.value,
        bpartnerId: loggedUser.bpartnerId,
      };
      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/customer/checkNicExist",
          data: reqData,
        });
        resolve(result);
      });
      responseData.then((response_data) => {
        if (response_data) {
          switch (response_data.existType) {
            case 0:
              nicNumber.current = nicIF.current.value;
              setCheckExistence(CHECKED);
              break;
            case 1:
              setCheckExistence(ABOUT_TO_CHECK);
              setCustomerExist(CUSTOMER_EXIST);
              new Promise((resolve) => {
                const result = sendRequest({
                  url: "/customer/searchProfile",
                  data: {
                    clientId: loggedUser.clientId,
                    orgIdList: loggedUser.activeOrgRoleList.map(
                      (org_item) => org_item.orgId
                    ),
                    orgId: loggedUser.orgId,
                    userId: loggedUser.userId,
                    customerId: "",
                    passportNo: "",
                    referenceId: "",
                    customerName: "",
                    nic: nicIF.current.value,
                    districtId: -1,
                    cc1DispositionId: -1,
                    cc2DispositionId: -1,
                    male: false,
                    female: false,
                    minAge: 0,
                    maxAge: 0,
                  },
                });
                resolve(result);
              }).then((response_data) => {
                if (response_data.customerList.length > 0) {
                  setCustomerData(response_data.customerList[0]);
                }
              });
              Swal.fire({
                icon: "warning",
                title: "Exists",
                text: "Customer Already Exists",
                showConfirmButton: false,
                timer: 5000,
              });
              break;
            default:
              break;
          }
        }
      });
    }
  };

  const directToCustomerProfile = () => {
    // console.log(customerData);
    dispatch({
      type: "STORE_CUSTOMER",
      payload: {
        bpartnerId: customerData.bpartnerId,
        clientId: customerData.clientId,
        orgId: customerData.orgId,
        searchKey: customerData.customerId,
      },
    });
    window.open(
      `${ConfigDB.data.hosted_url}/customer/view/${customerData.customerId}/`,
      "_blank"
    );
  };

  const directToCustomerApplication = () => {
    dispatch({
      type: "STORE_CUSTOMER",
      payload: {
        bpartnerId: bPartnerId,
        clientId: clientId,
        orgId: orgId,
        searchKey: searchKey,
      },
    });
    window.open(
      `${ConfigDB.data.hosted_url}/customer/view/${searchKey}/cv/`,
      "_blank"
    );
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (customer_id !== undefined) {
      setBasicTab(tab_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Breadcrumb parent="Dashboard" title="Customer" children={childLinks} />
      <div className="container-fluid">
        <div className="row">
          {triggerShourtcut ? (
            <div className="col-sm-12 mb-2">
              <div className="bg-primary px-3 py-3 rounded d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  <div
                    style={{
                      backgroundColor: "#FFEBB2",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50px",
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Check
                      height={"16px"}
                      style={{ strokeWidth: 5 }}
                      className="txt-primary"
                    />
                  </div>
                  <div className="mx-2">
                    <div className="mb-1 d-flex align-items-center">
                      <h6
                        style={{ color: "#FFEBB2" }}
                        className="uppercase px-1 m-0"
                      >
                        {searchKey}
                      </h6>
                      <div
                        style={{ backgroundColor: "#FFEBB2", height: "5px" }}
                        className="w-100 rounded ml-2"
                      ></div>
                    </div>
                    <div style={{ color: "#FFEBB2" }} className="fw-bold">
                      <UserCheck height={"14px"} style={{ strokeWidth: 3 }} />
                     {` ${firstName}’s`} Profile has successfully created.
                    </div>
                  </div>
                </div>
                <div
                  className="px-3 btn fw-bold"
                  style={{ backgroundColor: "#FFEBB2", color: "#777777" }}
                  onClick={directToCustomerApplication}
                >
                  Create Application
                </div>
              </div>
            </div>
          ) : null}
          <div className="col-sm-12">
            {checkExistence === CHECKED ? (
              <div className="card-modified">
                <div className="card-modified-header">
                  {/* <div>{customer_id}</div>
                <div>{bPartnerId}</div>
                <div>{bPartnerId !== 0 && customer_id}</div> */}
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${
                          basicTab === "save_customer_tab_1"
                            ? "active"
                            : "txt-primary"
                        }`}
                        onClick={() => setBasicTab("save_customer_tab_1")}
                      >
                        Personal
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${
                          basicTab === "save_customer_tab_2"
                            ? "active"
                            : "txt-primary"
                        }`}
                        onClick={() => setBasicTab("save_customer_tab_2")}
                      >
                        <div>Job</div>
                        {basicTab === "save_customer_tab_2" &&
                        bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${
                          basicTab === "save_customer_tab_3"
                            ? "active"
                            : "txt-primary"
                        }`}
                        onClick={() => setBasicTab("save_customer_tab_3")}
                      >
                        <div>Language</div>
                        {basicTab === "save_customer_tab_3" &&
                        bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${
                          basicTab === "save_customer_tab_4"
                            ? "active"
                            : "txt-primary"
                        }`}
                        onClick={() => setBasicTab("save_customer_tab_4")}
                      >
                        <div>Skill</div>
                        {basicTab === "save_customer_tab_4" &&
                        bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${
                          basicTab === "save_customer_tab_5"
                            ? "active"
                            : "txt-primary"
                        }`}
                        onClick={() => setBasicTab("save_customer_tab_5")}
                      >
                        <div>Contact</div>
                        {basicTab === "save_customer_tab_5" &&
                        bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${
                          basicTab === "save_customer_tab_6"
                            ? "active"
                            : "txt-primary"
                        }`}
                        onClick={() => setBasicTab("save_customer_tab_6")}
                      >
                        <div>Passport</div>
                        {basicTab === "save_customer_tab_6" &&
                        bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${
                          basicTab === "save_customer_tab_7"
                            ? "active"
                            : "txt-primary"
                        }`}
                        onClick={() => setBasicTab("save_customer_tab_7")}
                      >
                        <div>Experience</div>
                        {basicTab === "save_customer_tab_7" &&
                        bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${
                          basicTab === "save_customer_tab_8"
                            ? "active"
                            : "txt-primary"
                        }`}
                        onClick={() => setBasicTab("save_customer_tab_8")}
                      >
                        <div>Education</div>
                        {basicTab === "save_customer_tab_8" &&
                        bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${
                          basicTab === "save_customer_tab_9"
                            ? "active"
                            : "txt-primary"
                        }`}
                        onClick={() => setBasicTab("save_customer_tab_9")}
                      >
                        <div>Family</div>
                        {basicTab === "save_customer_tab_9" &&
                        bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${
                          basicTab === "save_customer_tab_10"
                            ? "active"
                            : "txt-primary"
                        }`}
                        onClick={() => setBasicTab("save_customer_tab_10")}
                      >
                        <div>Remark</div>
                        {basicTab === "save_customer_tab_10" &&
                        bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${
                          basicTab === "save_customer_tab_11"
                            ? "active"
                            : "txt-primary"
                        }`}
                        onClick={() => setBasicTab("save_customer_tab_11")}
                      >
                        <div>Detail In Sinhala</div>
                        {basicTab === "save_customer_tab_11" &&
                        bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${
                          basicTab === "save_customer_tab_12"
                            ? "active"
                            : "txt-primary"
                        }`}
                        onClick={() => setBasicTab("save_customer_tab_12")}
                      >
                        <div>Customer Status</div>
                        {basicTab === "save_customer_tab_12" &&
                        bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${
                          basicTab === "save_customer_tab_13"
                            ? "active"
                            : "txt-primary"
                        }`}
                        onClick={() => setBasicTab("save_customer_tab_13")}
                      >
                        <div>Customer Photos</div>
                        {basicTab === "save_customer_tab_13" &&
                        bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${
                          basicTab === "save_customer_tab_14"
                            ? "active"
                            : "txt-primary"
                        }`}
                        onClick={() => setBasicTab("save_customer_tab_14")}
                      >
                        <div>Connected Source</div>
                        {basicTab === "save_customer_tab_14" &&
                        bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${
                          basicTab === "save_customer_tab_15"
                            ? "active"
                            : "txt-primary"
                        }`}
                        onClick={() => setBasicTab("save_customer_tab_15")}
                      >
                        <div>Visit Details</div>
                        {basicTab === "save_customer_tab_15" &&
                        bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
                <TabContent activeTab={basicTab}>
                  <TabPane className="fade show" tabId="save_customer_tab_1">
                    {basicTab === "save_customer_tab_1" ? (
                      customer_id === undefined ? (
                        <Personal
                          b_partner_id={bPartnerId}
                          set_b_partner_id={setbPartnerFromChild}
                          set_search_key={setSearchKey}
                          set_client_id={setClientFromChild}
                          set_org_id={setOrgFromChild}
                          set_first_name={setFirstName}
                          nic_no={nicNumber?.current}
                          trigger_shortcut={setTriggerShourtcut}
                        />
                      ) : (
                        <Personal
                          b_partner_id={bPartnerId}
                          set_b_partner_id={setbPartnerFromChild}
                          set_client_id={setClientFromChild}
                          set_org_id={setOrgFromChild}
                        />
                      )
                    ) : null}
                  </TabPane>
                  <TabPane tabId="save_customer_tab_2">
                    {basicTab === "save_customer_tab_2" ? (
                      <Job
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="save_customer_tab_3">
                    {basicTab === "save_customer_tab_3" ? (
                      <Language
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="save_customer_tab_4">
                    {basicTab === "save_customer_tab_4" ? (
                      <Skill
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="save_customer_tab_5">
                    {basicTab === "save_customer_tab_5" ? (
                      <Contact
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="save_customer_tab_6">
                    {basicTab === "save_customer_tab_6" ? (
                      <Passport
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="save_customer_tab_7">
                    {basicTab === "save_customer_tab_7" ? (
                      <Experience
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="save_customer_tab_8">
                    {basicTab === "save_customer_tab_8" ? (
                      <Education
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="save_customer_tab_9">
                    {basicTab === "save_customer_tab_9" ? (
                      <Family
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="save_customer_tab_10">
                    {basicTab === "save_customer_tab_10" ? (
                      <Remark
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="save_customer_tab_11">
                    {basicTab === "save_customer_tab_11" ? (
                      <DetailInSinhala
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="save_customer_tab_12">
                    {basicTab === "save_customer_tab_12" ? (
                      <CustomerStatus
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="save_customer_tab_13">
                    {basicTab === "save_customer_tab_13" ? (
                      <Photo
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="save_customer_tab_14">
                    {basicTab === "save_customer_tab_14" ? (
                      <ConnectedSource
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="save_customer_tab_15">
                    {basicTab === "save_customer_tab_15" ? (
                      <VisitDetail
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                      />
                    ) : null}
                  </TabPane>
                </TabContent>
              </div>
            ) : (
              <div className="card">
                <div className="d-flex">
                  <form
                    onSubmit={(evt) => checkCustomer(evt)}
                    className="card-body col-md-3 col-12"
                    noValidate
                  >
                    <h5 className="fw-bold mb-4">
                      Check Customer Availability
                    </h5>
                    <div className="form-group w-100 position-relative mb-4">
                      <label htmlFor="id_input_fm_min_nic_no">NIC Number</label>
                      <input
                        id="id_input_fm_min_nic_no"
                        type="text"
                        ref={nicIF}
                        className="form-control"
                        placeholder="Enter NIC Number"
                        autoComplete="off"
                        maxLength={20}
                        onChange={(evt) =>
                          formValidation({
                            event: evt,
                            validateNIC: true,
                          })
                        }
                        required
                      />
                      <small className="invalid-feedback position-absolute">
                        Please enter NIC Number
                      </small>
                      <small className="txt-danger position-absolute d-none input-validation"></small>
                    </div>
                    <div className="form-group w-100 form-row mt-3 mb-0 px-1 mb-4">
                      <button className="btn btn-primary w-100" type="submit">
                        {checkExistence === ABOUT_TO_CHECK
                          ? "Check"
                          : "Checking..."}
                      </button>
                    </div>
                    {customerExist === CUSTOMER_EXIST ? (
                      <div
                        className="px-1 btn btn-outline-warning w-50"
                        onClick={directToCustomerProfile}
                      >
                        Visit Customer Profile
                      </div>
                    ) : null}
                  </form>
                  <img
                    width="300px"
                    className="d-none d-md-block"
                    src={LoadStaticURL(searchImage)}
                    alt=""
                  />
                </div>
                <img
                  width="100%"
                  className="d-block d-md-none"
                  src={LoadStaticURL(searchImage)}
                  alt=""
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
